import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from 'gatsby-link';
import { SignUpBtn } from '.';

export default class WholesaleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: null, email: null, businessName: null, message: null };
    }

    encode = (data) =>
        Object.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&');

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch('/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: this.encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error));
    };

    render() {
        return (
            <form
                name="wholesale"
                method="post"
                action="/wholesale/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
            >
                <input type="hidden" name="form-name" value="wholesale" />
                <div hidden>
                    <label htmlFor="bot-field">
                        Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                    </label>
                </div>
                <h2 className="has-text-weight-bold is-size-2">Wholesale Enquiry</h2>
                <div className="field">
                    <label className="label" htmlFor="name">
                        Name
                        <div className="control">
                            <input
                                className="input"
                                required
                                type="text"
                                name="name"
                                onChange={this.handleChange}
                                id="name"
                            />
                        </div>
                    </label>
                </div>
                <div className="field">
                    <label className="label" htmlFor="name">
                        Email
                        <div className="control">
                            <input
                                className="input"
                                required
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                id="email"
                            />
                        </div>
                    </label>
                </div>
                <div className="field">
                    <label className="label" htmlFor="businessName">
                        Business Name
                        <div className="control">
                            <input
                                className="input"
                                required
                                type="text"
                                name="businessName"
                                onChange={this.handleChange}
                                id="businessName"
                            />
                        </div>
                    </label>
                </div>
                <div className="field">
                    <label className="label" htmlFor="message">
                        Message
                        <div className="control">
                            <textarea
                                className="textarea"
                                type="text"
                                name="message"
                                onChange={this.handleChange}
                                id="message"
                            />
                        </div>
                    </label>
                </div>
                <div className="field">
                    <SignUpBtn className="button" type="submit">
                        Send
                    </SignUpBtn>
                </div>
            </form>
        );
    }
}
