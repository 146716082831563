import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Layout, StyledHeroImage, Content, MultiLineText } from '../components';
import WholesaleForm from '../components/WholesaleForm';

const WholesaleText = styled(MultiLineText)`
    margin: 2rem 0;
    > p {
        font-size: 1.2rem;
    }
`;

export const WholesalePageTemplate = ({ image, blurb, heading }) => (
    <>
        <StyledHeroImage vh={60} image={image} className="hero" />
        <Content>
            <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">{heading}</h2>
            <WholesaleText description={blurb} />
            <WholesaleForm />
        </Content>
    </>
);

WholesalePageTemplate.propTypes = {
    image: PropTypes.string.isRequired,
    blurb: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
};

const WholesalePage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout title={frontmatter.title} description={frontmatter.description} image={frontmatter.image}>
            <WholesalePageTemplate image={frontmatter.image} blurb={frontmatter.blurb} heading={frontmatter.heading} />
        </Layout>
    );
};

WholesalePage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                heading: PropTypes.string.isRequired,
                blurb: PropTypes.string.isRequired,
            }),
        }),
    }),
};

export default WholesalePage;

export const pageQuery = graphql`
    query WholesalePageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "wholesale-page" } }) {
            frontmatter {
                title
                image
                description
                heading
                blurb
            }
        }
    }
`;
